import React, {useState} from 'react';

import BibTex from './bibtex'

import './css/publication.css';
import './css/responsive-text.css';


/*******************************************************************************
Publication
*******************************************************************************/


export default function Publication({metadata}) {
  /* Render one publication */
  // Dropdown state
  const [active, setActive] = useState(false);

  // Render
  return (
    <div className='p-1'>
      <div className='row m-0'>
        <div
          className='d-flex text-18-responsive w-100 pointer'
          onClick={() => setActive(!active)}
        >
          <Arrow active={active}/>
          <Date metadata={metadata}/>
          <div className='d-flex w-100 row pr-2 justify-content-center'>
            <Title metadata={metadata}/>
            <Authors metadata={metadata}/>
          </div>
        </div>
      </div>
      <Extra active={active} metadata={metadata}/>
    </div>);

}


/*******************************************************************************
Subcomponents
*******************************************************************************/


function Abstract({metadata}) {
  /* Render the publication abstract */
  return (
    <div className='dropdown-abstract'>
      <div className='text-22-responsive pb-1'>Abstract</div>
      <div className='text-16-responsive line-height-140'>
        {get(metadata, 'abstract')}
      </div>
    </div>);
}

function Arrow({active}) {
  /* Render the dropdown arrow */
  return (
    <div className='arrow text-center'>
      <i className={'fas fa-caret-' + (active ? 'down' : 'right')} />
    </div>);
}


function Authors({metadata}) {
  /* Render the author list */
  return <div className='col px-1'>{getAuthors(metadata)}</div>;
}


function Button({name, item, icon, metadata}) {
  /* Render one button on the icon bar */
  let value = get(metadata, item);
  if (value) {
    value = item === 'key' ? '/pdfs/' + value + '.pdf' : value;
    return (
      <a  className='iconbox' href={value}>
        <i className={icon + ' text-24-responsive pb-1'}></i>
        <div className='line-height-100 text-22-responsive'>{name}</div>
      </a>);
  } else { return null; }
}


function Date({metadata}) {
  /* Render the month and year of publication */
  return (
    <div className='date'>
      {`${get(metadata, 'month').slice(0, 3)} ${get(metadata, 'year')}`}
    </div>);
}


function Extra({active, metadata}) {
  /* Render the dropdown menu */
  // Render nothing if dropdown is not active
  if (!active) { return null; }

  // Render dropdown
  return (
    <div className='extrasbox'>
      <Icons metadata={metadata}/>
      <div className='d-flex w-100 row m-0'>
        <Abstract metadata={metadata}/>
        <div className='dropdown-citation'>
          <div className='row pad-large pb-1 m-0 pl-0'>
            <div className='text-22-responsive w-100'>Citation</div>
            <div className='text-16-responsive line-height-140 inline-text pb-2'>
              {' ' + getAuthors(metadata) + ', "' + get(metadata, 'title') + '," '}
              <i className='inline-text'>{get(metadata, 'booktitle')}</i>
              {'. ' + get(metadata, 'month') + ' ' + get(metadata, 'year') + '.'}
            </div>
          </div>
          <div className='row pad-large m-0 mr-1 pl-0'>
            <div className='text-22-responsive w-100'>BibTex</div>
            <div className='text-16-responsive line-height-140 pb-2'>
              <BibTex bibtex={metadata}/>
            </div>
          </div>
        </div>
      </div>
    </div>);
}


function Icons({metadata}) {
  /* Render the icon bar */
  return (
    <div className='d-flex w-100 align-items-center justify-content-around my-1'>
      <Button
        icon={'far fa-file'}
        item={'paper'}
        metadata={metadata}
        name={'Paper'}
      />
      <Button
        icon={'fas fa-code-branch'}
        item={'github'}
        metadata={metadata}
        name={'Code'}
      />
      <Button
        icon={'fas fa-indent'}
        item={'website'}
        metadata={metadata}
        name={'Website'}
      />
      <Button
        icon={'fas fa-columns'}
        item={'poster'}
        metadata={metadata}
        name={'Poster'}
      />
      <Button
        icon={'fas fa-film'}
        item={'youtube'}
        metadata={metadata}
        name={'Video'}
      />
    </div>);
}


function Title({metadata}) {
  /* Render the publication title */
  return <div className='col px-1'>{get(metadata, 'title')}</div>;
}


/*****************************************************************************
Utilities
*****************************************************************************/


function get(metadata, property) {
  /* Retrieve one piece of metadata */
  try {
    if (['key', 'type'].includes(property)) {
      return metadata[property];
    } else {
      return metadata[property.toUpperCase()];
    }
  } catch(error) { return null; }
}


function getAuthors(metadata) {
  /* Retrieve the author list */
  // Get raw string from bibtex
  let authors = get(metadata, 'author').replace('\\\'e', 'é').split(' and ');

  // Parse individual names
  let result = [];
  for (const author of authors) {
    result.push(author.split(', ').reverse().join(' '));
  }

  // And "and" before final name
  result[result.length - 1] = 'and '.concat(result[result.length - 1]);

  // Join names
  let token = result.length === 2 ? ' ' : ', ';
  return result.join(token)
}
